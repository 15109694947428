import React, { Fragment } from 'react';
import styles from './styles.module.scss';

import zerotwo from './images/zerotwo.png';
import bubu from './images/bubu.png';
import death from './images/death.png';
import fishlips from './images/fishlips.png';
import iamham from './images/iamham.png';
import limpcane from './images/limpcane.png';

function NewsCommentForm() {
  const thinkers = [zerotwo, bubu, death, fishlips, iamham, limpcane];
  const thinkerSrc = thinkers[Math.floor(Math.random() * thinkers.length)];

  return (
    <Fragment>
      <div className={styles.newsCommentForm}>Comments are closed</div>
      <img src={thinkerSrc} alt="Thinker" className={styles.thinker} />
    </Fragment>
  );
}

export default NewsCommentForm;
