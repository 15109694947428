import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.scss';

import NewsCommentForm from '../NewsCommentForm';

function ordinalise(day) {
  const dayEndDigit = day % 10;

  if (dayEndDigit === 1 && day !== 11) {
    return day + 'st';
  }
  if (dayEndDigit === 2 && day !== 12) {
    return day + 'nd';
  }
  if (dayEndDigit === 3 && day !== 13) {
    return day + 'rd';
  }
  return day + 'th';
}

function NewsComments({ comments }) {
  return (
    <Fragment>
      <ul className={styles.newsComments}>
        {comments.map(comment => {
          const isTeamMember = ['Frankie', 'Manna', 'Cryptourniquet'].includes(
            comment.avatar
          );

          const date = new Date(comment.date);
          const weekday = date.toLocaleDateString('en', { weekday: 'long' });
          const day = ordinalise(
            parseInt(date.toLocaleDateString('en', { day: 'numeric' }), 10)
          );
          const month = date.toLocaleDateString('en', { month: 'long' });
          const year = date.toLocaleDateString('en', { year: 'numeric' });
          const time = date.toLocaleTimeString('en', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });
          const dateText = `${weekday}, ${day} ${month} ${year} at ${time.toLowerCase()}`;

          return (
            <li
              key={comment.date}
              className={classnames(styles.comment, {
                [styles.isTeamMember]: isTeamMember,
              })}
            >
              <div className={styles.heading}>
                <div
                  className={classnames(
                    styles.avatar,
                    styles[`avatar${comment.avatar}`]
                  )}
                />

                <div>
                  <div className={styles.author}>{comment.author}</div>

                  <div className={styles.meta}>{dateText}</div>
                </div>
              </div>

              <div
                className={styles.content}
                dangerouslySetInnerHTML={{
                  __html: comment.message.replace(/\n/g, '<br />'),
                }}
              />
            </li>
          );
        })}
      </ul>

      <NewsCommentForm />
    </Fragment>
  );
}

NewsComments.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }).isRequired
  ),
};

export default NewsComments;
