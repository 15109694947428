import React from 'react';
import { graphql } from 'gatsby';

import Page from '../components/Page';
import SEO from '../components/seo';
import NewsNav from '../components/News/NewsNav';
import NewsContent from '../components/News/NewsContent';
import NewsPostStyled from '../components/News/NewsPostStyled';
import NewsPostLayout from '../components/News/NewsPostLayout';
import NewsComments from '../components/News/NewsComments';
import NewsFooter from '../components/News/NewsFooter';

function BlogPostTemplate({ data, pageContext }) {
  const {
    html,
    excerpt,
    frontmatter: { author, date, title, comments },
  } = data.markdownRemark;
  const { slug } = pageContext;

  return (
    <Page>
      <SEO title={title} description={excerpt} slug={slug} />

      <NewsNav showActive />

      <NewsContent>
        <div style={{ marginBottom: '5px' }}>
          <NewsPostLayout
            isLeadPost
            author={author}
            date={date}
            title={title}
            commentsCount={comments && JSON.parse(comments).length}
          >
            <NewsPostStyled>
              <span dangerouslySetInnerHTML={{ __html: html }} />
            </NewsPostStyled>
          </NewsPostLayout>
        </div>

        {comments && <NewsComments comments={JSON.parse(comments)} />}
      </NewsContent>

      <NewsFooter />
    </Page>
  );
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        date(formatString: "dddd, Do MMMM YYYY")
        title
        author
        comments
      }
    }
  }
`;
